.ChartContainers {
    flex: 1 1 0%;
    margin: 10px;
    padding: 0px;
    height: 500px;
    background-color: #272b34!important;
    color: #dddddd !important;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms !important;
    padding: 5px;
}

.ChartContainers:hover {
    box-shadow: 0px 0px 10px 0px #777777;
}