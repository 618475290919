.CardContainer {
    display: flex;
    position: absolute;
    flex: 1 1 0%;
    margin-top: 100px;
    flex-direction: column;
    gap: 5px;
    width: 400px;
    height: calc(100% - 180px);
    bottom: 0px;
    right: 0px;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 50px;
    text-align: center;
}

.DateRangePicker {
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 220px;
    top: 20px;
    max-width: 140px;
}

.ToggleButton {
    position: absolute !important;
    right: 70px;
    top: 10px;
    color: white;
}