@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap");

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #212529;
  font-family: "Roboto", sans-serif;
  /* background-image: linear-gradient(
    90deg,
    rgba(91, 88, 144, 1) 0%,
    rgba(220, 155, 241, 1) 35%,
    rgba(162, 209, 110, 1) 78%,
    rgba(0, 212, 255, 1) 100%
  ); */
}

.SignBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  box-shadow: 1px 1px 10px 1px black;
  position: absolute;
  z-index: 2;
  border-radius: 20px;
  /* background-color: #262a32; */
}
.SignBoxBackground {
  background-color: #262a32;
  /* background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(136,136,136,1) 75%, rgba(150,150,150,1) 100%); */
  opacity: 0.5;
  display: flex;
  gap: 15px;
  flex-direction: column;
  z-index: 2;
  padding: 50px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.input_box {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 100%;
  opacity: 1;
}

.pass {
  color: transparent;
}
.pass::selection {
  color: transparent;
}
.input_box:focus {
  outline: none;
  box-shadow: 0px 0px 5px 2px #7460ee;
}

.CheckBoxText {
  -webkit-font-smoothing: subpixel-antialiased;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.loginButton {
  width: 200px;
  padding: 5px;
  margin: 10px 30px;
  border-radius: 5px;
  border: none;
  background-color: #7460ee;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.5em;
  opacity: 1;
}

.loginButton:focus {
  outline: none;
}

.Checkbox {
  background-color: #7460ee;
}
