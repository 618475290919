@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap");

.FilterBar {
  content: "";
  background-color: #ccc;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 40px;
  z-index: 3;
}
.InfoText {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 10px;
}

.FilterLabel {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.SideBarOptionIcon {
  flex: 1;
  font-size: 20px;
}

.Chips {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
}

.CenteredButtonWrapper {
  width: auto;
  height: auto;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin-bottom: -32px;
  z-index: 2;
}

/* .CenteredButton::before {
  content: "";
  background-color: #fff;
  padding: 5px;
  width: 20px;
  height: 1px;
  border-radius:  0% 0% 50% 50%; 
  border: 11px solid whitesmoke;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
  margin-right: -23px;
  cursor: pointer;
} */
.CenteredButton{
  cursor: pointer;
  text-align: center;
}

