@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.Card {
    z-index: 1;
    width: 220px;
    height: 130px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    border-radius: 20px;
    cursor:default;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
    margin: 10px 10px;
}
.CardRow {
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: space-between;
    align-items:space-between;
}

.CardRow:first-child {
    flex: 1;
    align-items: center;
}

.CardRow:last-child {
    justify-content: flex-end;
    align-items: flex-end;
}

.Value {
    font-size: 2em;
    justify-content: flex-end;
    align-items: flex-start;
}

.CardRow:first-child > .Title {
    font-size: 1.1em;
}

.CardRow:last-child > .Title {
    font-size: 1.2em;
}

.Description {
    text-align: justify;
    font-size: 0.75em;
    overflow: hidden;
}

.Icon {
    font-size: 1.5em;
}
