@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

a {
    text-decoration: none;
}

.SideBar {
    display: flex;
    flex-direction: column;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
    background-color: #414755;
}

.SideBarToggleButton {
    padding: 20px;
}

.UserAvatar{
    height: 64px;
    width: 64px;
    border-radius: 32px;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.UserInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    padding-left: 25px;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.UserNameAndDesignation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    flex: 1;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
}

.UserName {
    font-size: 14px;
    color: #cccccc;
    text-align: left;
}

.UserDesignation {
    font-size: 13px;
    color: #bbbbbb;
}

.AccountSettingsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.AccountSettingsButton {
    border: 1px solid #ccc;
    padding: 10px;
    color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 750ms;
}
.AccountSettingsButton:hover {
    background-color: #cccccc1c;
    padding-left: 20px;
    padding-right: 20px;
}

.SideBarOption {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 500ms;
}
.SideBarOption:hover {
    background-color: #4f5467;
    padding-left: 30px;
}

.SideBarSubOption {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 10px 20px;
    border-left: 10px solid #757b94;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 500ms;
}
.SideBarSubOption:hover {
    background-color: #4f5467;
    padding-left: 20px;
}

.SideBarOptionActive {
    background-color: #4f5467;
    padding-left: 30px;
}

.SideBarOptionTitle {
    padding-left: 20px;
    color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    flex: 3;
    text-align: left;
}

.SideBarOptionIcon {
    flex: 1;
    font-size: 20px;
}