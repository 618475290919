.App {
  text-align: center;
  background-color: #323743;
}
.multi-select{
  width: 200px;
}
.dropdown-heading-value, .dropdown-content{
  color: #000 !important;
  text-align: left !important;
}
