@import url('https://cdn.syncfusion.com/ej2/material.css');
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

.e-grid{
    /* overflow:'scroll'; */
    /* width: '100%'; */
}

.e-grid .e-headercell {
    color: darkblue;
 }
 /* .isLatLong {
    background-color: green;
  } */