
.custom-bg-yellow {
    background-color: yellow !important;
    color: black !important;
}

.custom-bg-yellow:hover {
    background-color: rgba(255, 255, 0, 0.75) !important;
    color: black !important;
}

.custom-bg-red {
    background-color: red !important;
    color: white !important;
}
.custom-bg-red:hover {
    background-color: rgba(255, 0, 0, 0.75) !important;
    color: white !important;
}

.custom-bg-green {
    background-color: green !important;
    color: white !important;
}
.custom-bg-green:hover {
    background-color: rgba(0, 128, 0, 0.75) !important;
    color: white !important;
}

.custom-bg-white {
    background-color: white !important;
    color: black !important;
}

.custom-bg-white:hover {
    background-color: rgba(255, 255, 255, 0.75) !important;
    color: black !important;
}

.custom-bg-black {
    /* background-color: black !important; */
    color: white !important;
}

.custom-bg-black:hover {
    /* background-color: rgba(255, 255, 255, 0.75) !important; */
    color: white !important;
}