.CardContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 50px;
}

.DateRangePicker {
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 100px;
    top: 20px;
}

.ChartContainers {
    flex: 1 1 0%;
    margin: 10px;
    padding: 0px;
    background-color: #272b34!important;
    color: #dddddd !important;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms !important;
    padding: 5px;
}

.ChartContainers:hover {
    box-shadow: 0px 0px 10px 0px #777777;
}

h1 {
    font-family: "Poppins";
    color: #dddddd;
    text-align: left;
    padding: 10px 20px;
    font-size: 24px;
}